import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COUNTRY, tooltipStyle, defaultFunds } from '../../lib/utils';
import {
  Box,
  Container,
  Stack,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddchartIcon from '@mui/icons-material/Addchart';
import MyFundList from './MyFundList';
import { getMyFunds, resetAuth } from '../../features/auth/authSlice';

const MyFund = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token, myFunds } = useSelector(state => state.auth);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [country, setCountry] = useState(COUNTRY.KOREA);
  const myFundList = useMemo(() => {
    if (country === COUNTRY.KOREA) {
      return myFunds && myFunds.kr ? [...myFunds.kr] : [...defaultFunds];
    } else {
      return myFunds && myFunds.us ? [...myFunds.us] : [...defaultFunds];
    }
  }, [country, myFunds]);
  const tabLists = useMemo(() => {
    if (country === COUNTRY.KOREA) {
      return [
        { id: 0, label: 'PRIMARY', value: 'primary_kr' },
        { id: 1, label: 'SECONDARY', value: 'secondary_kr' },
        { id: 2, label: 'INDEX', value: 'index_kr' },
        { id: 3, label: 'WATCH LIST', value: 'watchlist_kr' }
      ];
    } else {
      return [
        { id: 0, label: 'PRIMARY', value: 'primary_us' },
        { id: 1, label: 'SECONDARY', value: 'secondary_us' },
        { id: 2, label: 'INDEX', value: 'index_us' },
        { id: 3, label: 'WATCH LIST', value: 'watchlist_us' }
      ];
    }
  }, [country]);

  const [tabValue, setTabValue] = useState(tabLists[0].id);

  const normalizeFundListOrder = () => {
    const index = myFundList.filter(fund => fund.name === 'INDEX');

    index.length === 0 && myFundList.push({ name: 'INDEX', assets: [] });

    const myFundListLength = myFundList.length;
    const indexWatchList = myFundList.findIndex(fund => fund.name === 'WATCH LIST');

    if (indexWatchList !== myFundListLength - 1) {
      [myFundList[indexWatchList], myFundList[myFundListLength - 1]] = [
        myFundList[myFundListLength - 1],
        myFundList[indexWatchList]
      ];
    }
  };

  normalizeFundListOrder();

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }

    setTimeout(() => {
      setTabValue(tabLists[0].id);
    }, 0);

    const promise = dispatch(getMyFunds());

    return () => {
      promise.abort();
      dispatch(resetAuth());
    };
  }, [token, tabLists, dispatch, navigate]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
      >
        <AddchartIcon fontSize={isMobile ? 'medium' : 'large'} /> My Fund
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '800px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', typography: 'body1' }}
      >
        <Stack direction='row' justifyContent='flex-end' alignItems='center' mb={3}>
          <Tooltip disableFocusListener arrow title='Fund Country' PopperProps={{ sx: tooltipStyle }}>
            <ToggleButtonGroup
              size='small'
              color='primary'
              value={country}
              exclusive
              onChange={(e, country) => {
                if (country !== null) setCountry(country);
              }}
              aria-label='Trade Country'
              sx={{ width: '150px' }}
            >
              <ToggleButton value={COUNTRY.KOREA}>Korea</ToggleButton>
              <ToggleButton value={COUNTRY.USA}>USA</ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>
        </Stack>

        <TabContext key={tabValue} value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(e, value) => setTabValue(value)}
              variant={isMobile ? 'scrollable' : 'standard'}
              scrollButtons={isMobile ? true : false}
              allowScrollButtonsMobile={isMobile ? true : false}
            >
              {tabLists.map(list => (
                <Tab key={list.value} label={list.label} value={list.id} />
              ))}
            </TabList>
          </Box>

          {tabLists.map(list => (
            <TabPanel key={list.value} value={list.id}>
              <MyFundList key={list.value} fundId={list.id} myFundList={myFundList} country={country} />
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Container>
  );
};

export default MyFund;
